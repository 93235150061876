import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import useClinicService from "service-hooks/useClinicService";
import useNoteService from "service-hooks/useNoteService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import "../../../../style.css"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationModal from "examples/ConfirmationModal"
import AddModal from "../../../../components/AddModal"
import { formatTimestamp } from "parsers/formatParser";
import Spinner from "blikol-layouts/components/Spinner";
import PropTypes from "prop-types";
import { languageMapping } from "parsers/formatParser";
function Detail({ clinicInfo, htmlComponent: HtmlComponent, onPageRefreshChange }) {
    const navigate = useNavigate();
    const clinicHookService = useClinicService();
    const noteHookService = useNoteService();
    const [loading, setLoading] = useState(false);
    const [clinicData, setClinicData] = useState(clinicInfo);
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [clinicId, setClinicId] = useState(useParams().id);
    const [pageRefresh, setPageRefresh] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [noteId, setNoteId] = useState()
    const [openEditNoteModal, setOpenEditNoteModal] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [noteData, setNoteData] = useState()

    useEffect(() => {
        setClinicData(clinicInfo)
    }, [clinicInfo])

    const handleDelete = () => {
        noteHookService
            .destroy(noteId)
            .then(() => {
                toast.success("Note deleted successfully.");
                onPageRefreshChange();
            })
            .catch((error) => {
                toast.error(error);
            });
        setShowConfirmationModal(false)
    };
    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }
    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }
    const fieldEmailValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    function amount(value) {
        return `€${value}`;
    }

    const handleInputChange = (value) => {
        setInputValue(value);
    };


    const handleEditNote = (note) => {
        setNoteData(note)
        setInputValue(note?.note)
        setOpenEditNoteModal(true)

    }

    const handleFilledEditNote = () => {
        const updatedValues = {
            ...noteData,
            note: inputValue,
        };
        const response = noteHookService.update(updatedValues, noteData?.id)
        if (response) {
            toast.success("Note updated successfully");
            setInputValue("");
            setOpenEditNoteModal(false);
            onPageRefreshChange();
        } else {
            toast.error("Failed to update note");
        }
    }

    const handleOutlinedEditNote = () => {
        setOpenEditNoteModal(false);
        setInputValue("");
    }

    return (
        <>
            {loading && <Spinner />}
            {!loading && (
                <ArgonBox px={3} pt={3} mb={3}>
                    <ArgonBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <ArgonTypography
                            mb={3}
                            mt={4}
                            sx={{
                                color: "var(--Default, #172B4D)",
                                fontSize: "25px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "137%",
                                letterSpacing: "-0.8px",
                                textTransform: "capitalize"
                            }}
                        >
                            {clinicData?.name}
                        </ArgonTypography>
                        {HtmlComponent && <HtmlComponent />}
                    </ArgonBox>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={4} >
                            <Card
                                sx={{
                                    borderRadius: '20px',
                                    background: '#FFF',
                                    boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.05)',
                                }}
                            >
                                <Grid item xs={12} sm={12} p={3}>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: '137%',
                                            letterSpacing: '-0.8px',
                                        }}
                                        mb={2}
                                    >
                                        Clinic Info
                                    </ArgonTypography>

                                    <Card
                                        sx={{
                                            padding: '30px',
                                            gap: '10px',
                                            borderRadius: '20px',
                                            background: '#F4F2F2',
                                        }}
                                    >
                                        <ArgonBox
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '16px',
                                            }}
                                        >
                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Name
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.name}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Email
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldEmailValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.email}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Phone
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.phone_number}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Agent
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.created_by_obj.full_name ? clinicData?.created_by_obj.full_name : "No agent assigned"}
                                                </ArgonTypography>
                                            </ArgonBox>

                                        </ArgonBox>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} p={3}>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: '137%',
                                            letterSpacing: '-0.8px',
                                        }}
                                        mb={2}
                                    >
                                        Contact Person Info
                                    </ArgonTypography>

                                    <Card
                                        sx={{
                                            padding: '30px',
                                            gap: '10px',
                                            borderRadius: '20px',
                                            background: '#F4F2F2',
                                        }}
                                    >
                                        <ArgonBox
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '16px',
                                            }}
                                        >
                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Name
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.contact_person_info?.name}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Phone
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.contact_person_info?.phone_number}
                                                </ArgonTypography>
                                            </ArgonBox>
                                        </ArgonBox>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} p={3}>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: '137%',
                                            letterSpacing: '-0.8px',
                                        }}
                                        mb={2}
                                    >
                                        Clinic Address
                                    </ArgonTypography>

                                    <Card
                                        sx={{
                                            padding: '30px',
                                            gap: '10px',
                                            borderRadius: '20px',
                                            background: '#F4F2F2',
                                        }}
                                    >
                                        <ArgonBox
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '16px',
                                            }}
                                        >

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Address
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.address1}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    City
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.city}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Postal Code
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.postal_code}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    State/Province
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.state}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Country
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {clinicData?.country}
                                                </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    Language
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={fieldValueStyle}
                                                    my={1}
                                                >
                                                    {languageMapping[clinicData._language]}
                                                </ArgonTypography>
                                            </ArgonBox>

                                        </ArgonBox>


                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} p={3}>
                                    <ArgonTypography
                                        sx={{
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: '137%',
                                            letterSpacing: '-0.8px',
                                        }}
                                        mb={2}
                                    >
                                        Financials
                                    </ArgonTypography>

                                    <Card
                                        sx={{
                                            padding: '30px',
                                            gap: '10px',
                                            borderRadius: '20px',
                                            background: '#F4F2F2',
                                        }}
                                    >
                                        <ArgonBox
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '16px',
                                            }}
                                        >
                                            {clinicData?.vat_payer && (
                                                <>
                                                    <ArgonBox>
                                                        <ArgonTypography sx={fieldNameStyle}>
                                                            VAT Number
                                                        </ArgonTypography>
                                                        <ArgonTypography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 600,
                                                            }}
                                                            my={1}
                                                        >
                                                            {clinicData?.vat_number}
                                                        </ArgonTypography>
                                                    </ArgonBox>
                                                </>
                                            )}
                                            <ArgonBox>
                                                <ArgonTypography sx={fieldNameStyle}>
                                                    Tax Number
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                    }}
                                                    my={1}
                                                >
                                                    {clinicData?.tax_number}
                                                </ArgonTypography>
                                            </ArgonBox>
                                            <ArgonBox>
                                                <ArgonTypography
                                                    sx={fieldNameStyle}
                                                >
                                                    VAT Payer
                                                </ArgonTypography>
                                                <ArgonTypography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                    }}
                                                    my={1}
                                                >
                                                    {clinicData?.vat_payer === true ? "I am a VAT Payer" : "Not a VAT Payer"}
                                                </ArgonTypography>
                                            </ArgonBox>

                                        </ArgonBox>
                                    </Card>
                                </Grid>
                            </Card>

                        </Grid>
                        <Grid item xs={12} sm={8} >
                            <Card>
                                <ArgonBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                    pt={5}
                                    pb={2}
                                    px={4}
                                >
                                    <ArgonTypography
                                        sx={{
                                            color: 'var(--Default, #172B4D)',
                                            fontFamily: 'Open Sans',
                                            fontSize: '25px',
                                            fontStyle: 'normal',
                                            fontWeight: 700,
                                            lineHeight: '137%', // Converted from "137%"
                                        }}
                                    >
                                        Notes
                                    </ArgonTypography>
                                </ArgonBox>
                                <Grid item xs={12} sm={12} p={3}>
                                    {clinicData?.clinic_notes?.map((notes, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                padding: '30px',
                                                gap: '10px',
                                                borderRadius: '20px',
                                                background: '#F4F2F2',
                                                marginBottom: "25px",
                                            }}
                                        >
                                            <ArgonBox
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                <ArgonTypography
                                                    sx={{
                                                        color: '#172B4D',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 700,
                                                        lineHeight: '150%',
                                                        textTransform: "capitalize"
                                                    }}
                                                >
                                                    {(notes._type === "admin") ? `${notes._type}` : `${clinicData?.name} (${notes._type})`}
                                                </ArgonTypography>
                                                {(notes._type === "admin") && <ArgonBox sx={{ display: "flex", flexDirection: "row" }}>
                                                    <ArgonButton variant="text"
                                                        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                                        mx={1}
                                                        onClick={() => {
                                                            setShowConfirmationModal(true)
                                                            setNoteId(notes?.id)
                                                        }}
                                                    >
                                                        <DeleteIcon sx={{ color: "#F5365C" }} />
                                                        <ArgonTypography
                                                            sx={{
                                                                color: 'var(--Danger, #F5365C)',
                                                                fontSize: '14px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 700,
                                                            }}
                                                            ml={0.5}
                                                        >
                                                            Delete
                                                        </ArgonTypography>
                                                    </ArgonButton>
                                                    <ArgonButton variant="text" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} mr={1}
                                                        onClick={() => handleEditNote(notes)}
                                                    >
                                                        <EditIcon sx={{ color: "#172B4D" }} />
                                                        <ArgonTypography
                                                            sx={{
                                                                color: 'var(--Default, #172B4D)',
                                                                fontSize: '14px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 700,
                                                            }}
                                                            ml={0.5}
                                                        >
                                                            Edit
                                                        </ArgonTypography>
                                                    </ArgonButton>
                                                </ArgonBox>}
                                            </ArgonBox>
                                            <ArgonBox
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '16px',
                                                }}
                                            >
                                                <ArgonTypography
                                                    sx={{
                                                        color: 'var(--Label, #8392AB)',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                    }}
                                                >
                                                    {formatTimestamp(notes?.created_at)}
                                                </ArgonTypography>
                                                <ArgonBox>
                                                    <ArgonTypography sx={fieldNameStyle}>
                                                        Note
                                                    </ArgonTypography>
                                                    <ArgonTypography sx={fieldValueStyle} my={1}>
                                                        {notes.note}
                                                    </ArgonTypography>
                                                </ArgonBox>
                                            </ArgonBox>
                                        </Card>
                                    ))}
                                    {((!clinicData?.clinic_notes || clinicData?.clinic_notes.length === 0) && (clinicData?.note !== "")) && (
                                        <ArgonBox>
                                            <ArgonTypography sx={fieldNameStyle}>
                                                Note
                                            </ArgonTypography>
                                            <ArgonTypography sx={fieldValueStyle} my={1}>
                                                {clinicData?.note}
                                            </ArgonTypography>
                                        </ArgonBox>
                                    )}
                                </Grid>

                            </Card>
                        </Grid>
                    </Grid>
                    <ConfirmationModal
                        open={showConfirmationModal}
                        onClose={() => setShowConfirmationModal(false)}
                        onConfirm={handleDelete}
                        cancelButtonText={"Cancel"}
                        confirmButtonText={"Delete"}
                        confirmButtonColor={"red"}
                        modalHeadingText={"Delete"}
                        confirmationText={"Are you sure you want to delete the note?"}
                    />
                    <AddModal
                        open={openEditNoteModal}
                        headerContent="Edit Note"
                        subHeaderContent="Note"
                        filledBtnText="Update"
                        filledBtnAction={handleFilledEditNote}
                        outlinedBtnText="Cancel"
                        outlinedBtnAction={handleOutlinedEditNote}
                        inputValue={inputValue}  // Pass inputValue as a prop
                        setInput={handleInputChange}  // Pass setInput function as a prop
                    />

                </ArgonBox>
            )}
        </>

    )
}

Detail.defaultProps = {
    clinicInfo: {},
};

Detail.propTypes = {
    clinicInfo: PropTypes.object,
    htmlComponent: PropTypes.any, // Validate htmlComponent prop as a React element
    onPageRefreshChange: PropTypes.func

};

export default Detail