import React from "react";
//components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import DataTable from "examples/Tables/DataTable";
import Card from "@mui/material/Card";
import Header from "blikol-layouts/components/Header";
import Spinner from "blikol-layouts/components/Spinner";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFeedbackListingService from "../../service-hooks/useFeedbackListing";
import Grid from "@mui/material/Grid";
import useExportCSVService from "service-hooks/useExportCSVService";
import { formatName, formatEmail, formatTimestamp } from "parsers/formatParser";
function Sessions() {
    const { user, token } = useSelector((state) => state.auth);
    const userRole = user?.role;
    const [data, setData] = useState({ feedbacksData: [] });
    const navigate = useNavigate();
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        country: "",
        clinic: "",
    });
    const feedbackListingHookService = useFeedbackListingService();
    const [loading, setLoading] = useState(true);
    const [listTab, setListTab] = useState();
    const [dateRange, setDateRange] = useState("")
    const [status, setStatus] = useState("complete");
    const [listOfCountries, setListOfCountries] = useState([]);
    const exportCSVServiceHook = useExportCSVService();
    const [listOfClinics, setListOfClinics] = useState([]);
    useMemo(() => {
        feedbackListingHookService.countries().then((result) => {
            if (result) {
                setListOfCountries(result);
            }
        });
        feedbackListingHookService.clinics().then((result) => {
            const clinics = result?.map((clinic) => ({
                value: clinic[0], // Clinic ID or value
                label: clinic[1], // Clinic name
            }));
            setListOfClinics(clinics);
        });
    }, [pageRefresh, status]);

    useEffect(() => {
        feedbackListingHookService
            .feedbacks(
                pageNo,
                entriesPerPage,
                search,
                status,
                selectedOptions["country"],
                selectedOptions["clinic"],
                dateRange
            )
            .then((result) => {
                if (result && result.feedbacksData && result.feedbacksMeta) {
                    setData(result);
                    setLoading(false);
                    setTotalEntries(result?.feedbacksMeta?.total_count);
                    setTotalPages(result?.feedbacksMeta?.total_pages);
                } else {
                    toast.error("Data is undefined or incomplete.");
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                toast.error("Error fetching user data and attributes:", error);
            });
    }, [
        search,
        entriesPerPage,
        pageNo,
        pageRefresh,
        selectedOptions["country"],
        selectedOptions["clinic"],
        status,
        dateRange
    ]);

    function lowerFirstLetter(word) {
        if (typeof word !== "string" || word.length === 0) {
            return word;
        }
        return word.charAt(0).toLowerCase() + word.slice(1);
    }

    const handleTabChange = (selectedTab) => {
        selectedTab = lowerFirstLetter(selectedTab);
        setStatus(selectedTab);
        setPageNo(1)
    };

    const filters = [
        {
            name: "Country",
            action: (selectedValue) => handleFilterChange("country", selectedValue),
            listOfOptions: listOfCountries?.countries?.data,
        },
        {
            name: "Clinic",
            action: (selectedValue) => handleFilterChange("clinic", selectedValue),
            listOfOptions: listOfClinics,
        },
    ];

    const handleFilterChange = (filterName, selectedValue) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [filterName]: selectedValue,
        }));
    };

    const handleDateRangeChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const tableCell = (option) => {
        if (option && option.trim() !== "") {
            return option
                .replace(/[_-]/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        } else {
            return "";
        }
    };

    const feedbacksRowData = data.feedbacksData.map((feedback, index) => ({
        ...feedback,
        timestamp: formatTimestamp(feedback?.timestamp),
        patient_name: formatName(feedback?.patient_name),
        gender: tableCell(feedback?.gender),
        clinic_name: formatName(feedback?.clinic_name),
        frequency: feedback?.frequency,
    }));

    const tableData = {
        columns: [
            { Header: "clinic name", accessor: "clinic_name" },
            { Header: "name", accessor: "patient_name" },
            { Header: "frequency", accessor: "frequency" },
            { Header: "gender", accessor: "gender" },
            { Header: "country", accessor: "country" },
            { Header: "date and time", accessor: "timestamp" }, // Use the matured_at field
        ],
        rows: feedbacksRowData,
    };


    return (
        <DashboardLayout>
            <DashboardNavbar customRoutePath={"Sessions"} />
            <Grid container spacing={3} alignItems="center" mb={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Header onTabChange={handleTabChange} numTabs={2} tabNames={["Complete", "Incomplete"]} />
                </Grid>
            </Grid>
            {loading && <Spinner />}
            {!loading && (
                <Card p={1}>
                    <ArgonBox>
                        <DataTable
                            canSearch
                            canFilter
                            filters={filters}
                            dateFilter
                            onDateRangeChange={handleDateRangeChange}
                            dataTableName={"Sessions"}
                            entriesPerPage={entriesPerPage}
                            setSearch={setSearch}
                            search={search}
                            totalPages={totalPages}
                            setEntriesPerPage={setEntriesPerPage}
                            pageNo={pageNo}
                            setPageNo={setPageNo}
                            totalEntries={totalEntries}
                            table={tableData}
                            clickableRecords={false}
                            lastFixed={false}
                        />
                    </ArgonBox>
                </Card>
            )}
        </DashboardLayout>
    );
}

export default Sessions;
