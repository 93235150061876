import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolveConfig } from "prettier";

const useUserService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();
    const users = async (currentPage, itemsPerPage, query = "", role = "", status = "") => {
        try {
            const encodedQuery = encodeURIComponent(query)
            const response = await axiosRequest.get(
                `administrator_apis/administrators?page=${currentPage}&items_per_page=${itemsPerPage}&query=${encodedQuery}&role=${role}&status=${status == 'active' ? '' : status}`
            );
            const usersData = response.data.administrators;
            const usersMeta = response.data.meta;
            return { usersMeta, usersData };
        } catch (error) {
            toast.error(error.response.data.error);

            return false;
        }
    };

    const create = async (userData) => {
        try {
            const response = await axiosRequest.post(`administrator_apis/administrators`, userData);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };


    const update = async (userData, id) => {
        try {
            const response = await axiosRequest.put(`administrator_apis/administrators/${id}`, userData);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    const resendCredentails = async (id) => {
        try {
            const response = await axiosRequest.get(
                `administrator_apis/administrators/${id}/resend_login_credentials`
            );
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };


    const agents = async () => {
        try {
            const response = await axiosRequest.get(`administrator_apis/administrators/agents`);
            const agents = response.data;
            return agents
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const user = async (id) => {
        try {
            const response = await axiosRequest.get(`administrator_apis/administrators/${id}`);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    return {
        users,
        resendCredentails,
        update,
        user,
        create,
        agents
    };
};

export default useUserService;
